import React from 'react';
import OrganizationList from './resources/Agent/Actor/Organization/OrganizationList'

const HomePage = () => {
  //To do change this
  window.location.replace('/Organization');

  return (
    <></>
  );
};

export default HomePage;
